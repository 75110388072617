import React, { useRef, RefObject, useEffect, useState } from "react"
import "./BioSection.css"
import bioImage1 from "./../../../../images/bio/1.webp"
import bioImage2 from "./../../../../images/bio/2.webp"
import bioImage3 from "./../../../../images/bio/3.webp"

export default function BioSection(props: any) {
  const array = [
    {
      text: (
        <p className="bio-text">
          Take a deep breath before these progressive-aggressive Finns take you
          on a rollercoaster of sophisticated madness. Backing their dual scream
          attack with a multidimensional hybrid of down tuned high tech guitar
          riffs and jazz fusion piano licks, Humavoid delivers a blistering and
          unpredictable blend of Progressive Metal that effortlessly shifts from
          chaotic beating to contagious refrains and back.
        </p>
      ),
    },
    {
      text: (
        <p className="bio-text">
          Since its inception in 2014, Humavoid has put out a few independent
          releases with praising reviews and steadily grown their fanbase
          through vigorous live shows. In 2020 the band signed a recording
          contract with German label Noble Demon and released their latest album
          Lidless.
        </p>
      ),
    },
    {
      text: (
        <p className="bio-text">
          Lidless presents Humavoid as its most aggressive to date, but also
          further reveals the band’s experimental side. The songs – some of
          which contain high doses of improvised drum arrangements – are linked
          together by a couple of vocalist and keyboardist Suvimarja Halmetoja’s
          eerie and beautiful piano improvisations. Additional spontaneity is
          featured in the guest appearance by the great jazz pianist Iiro
          Rantala on the song ”Aluminum Rain”.
        </p>
      ),
    },
    {
      text: (
        <p className="bio-text">
          Unafraid to keep experimenting until the songs have reached their full
          potential, this northern four-piece has crafted their sound into a
          cohesive package.
        </p>
      ),
    },
    {
      text: (
        <p className="bio-text">
          The album “Lidless” finds a special a way to resonate within both the
          adventurous listeners as well as metalheads craving for a never-ending
          supply of headbang-inducing Metal.
        </p>
      ),
    },
    {
      text: (
        <div className="bio-text-mobile-members">
          Humavoid are:
          <div className="break" />
          Suvimarja Halmetoja
          <br />-<br />
          Vocals & Keys
          <div className="break" />
          Niko Kalliojärvi
          <br />-<br />
          Guitar & Vocals
          <div className="break" />
          Heikki Malmberg
          <br />-<br />
          Drums
          <div className="break" />
        </div>
      ),
    },
  ]

  const desktopArray = [
    {
      text: (
        <p className="bio-text">
          Suvimarja Halmetoja
          <br />
          <br />
          Vocals, Keyboards (2013-present)
          <br />
          <br />
          See also: ex-Dotma
        </p>
      ),
    },
    {
      text: (
        <p className="bio-text">
          Heikki Malmberg
          <br />
          <br />
          Drums (2018-present)
          <br />
          <br />
          See also: Diablo, ex-Wolfpack, ex-Crystalic, ex-Stam1na (live),
          ex-Bulldozer, ex-Snakegod, ex-Louna, ex-Manzana, ex-Tranquillitian,
          ex-Dame (live)
        </p>
      ),
    },
    {
      text: (
        <p className="bio-text">
          Niko Kalliojärvi
          <br />
          <br />
          Guitars, Vocals (2013-present)
          <br />
          <br />
          See also: ex-Amoral, ex-Dotma, ex-Flesh Deformation, ex-Lithuria,
          ex-Paingrin, ex-Tracedawn
        </p>
      ),
    },
  ]

  const imageArray = [
    {
      src: bioImage1,
      alt: "bioImage1",
      header: "",
    },
    {
      src: bioImage2,
      alt: "bioImage2",
      header: "",
    },
    {
      src: bioImage3,
      alt: "bioImage3",
      header: "",
    },
  ]

  const [index, setIndex] = useState(0)
  const [imageIndex, setImageIndex] = useState(0)
  const [isDisabled, setIsDisabled] = useState(false)
  const [desktopIndex, setDesktopIndex] = useState(0)
  const [isDisabledDesktop, setIsDisabledDesktop] = useState(false)
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null) // Track the hovered image index
  const [lastHoveredIndex, setLastHoveredIndex] = useState<number | null>(null) // Track the last hovered image index

  const shiftForward = () => {
    if (isDisabled) {
      return
    }

    const forwardButton = document.querySelector(
      ".bio-section-mobile-shift-carousel-forward-button"
    )
    forwardButton?.classList.add("faded")

    setTimeout(() => {
      forwardButton?.classList.remove("faded")
    }, 500) // 1 second delay before the button comes back. Adjust as needed.

    if (index === array.length - 1) {
      setIndex(0)
    } else if (index < array.length - 1) {
      setIndex((index + 1) % array.length)
    }

    setIsDisabled(true)

    setTimeout(() => {
      setIsDisabled(false)
    }, 500)
  }

  const shiftForwardDesktop = () => {
    if (isDisabledDesktop) {
      return
    }

    const forwardButton = document.querySelector(
      ".bio-section-desktop-shift-carousel-forward-button"
    )
    forwardButton?.classList.add("faded")

    setTimeout(() => {
      forwardButton?.classList.remove("faded")
    }, 500) // Adjust the delay as needed

    if (desktopIndex === desktopArray.length - 1) {
      setDesktopIndex(0)
    } else if (desktopIndex < desktopArray.length - 1) {
      setDesktopIndex((desktopIndex + 1) % desktopArray.length)
    }

    setIsDisabledDesktop(true)

    setTimeout(() => {
      setIsDisabledDesktop(false)
    }, 500)
  }

  const [showModal, setShowModal] = useState(false)
  const modalRef: RefObject<HTMLDivElement> = useRef(null)

  useEffect(() => {
    if (showModal) {
      const modalElement = modalRef.current
      if (modalElement) {
        const scrollY = window.scrollY
        console.log("scrolly " + scrollY)
        //modalElement.style.top = `${scrollY}px`; // Set the top position to the current scroll position
      }
    }
  }, [showModal])

  const twistBorderRef: RefObject<HTMLDivElement> = useRef(null)

  useEffect(() => {
    let lastScrollTop = 0

    const twistElement = twistBorderRef.current
    // Set initial values
    if (twistElement) {
      twistElement.style.setProperty("--_p1", "75%")
      twistElement.style.setProperty("--_p2", "175%")
    }

    const handleScroll = () => {
      const twistElement = twistBorderRef.current
      if (twistElement) {
        const rect = twistElement.getBoundingClientRect()
        if (rect.top < window.innerHeight && rect.bottom > 0) {
          // Check if BioSection is in the viewport
          let delta = window.scrollY - lastScrollTop

          let currentPercentage1String =
            getComputedStyle(twistElement).getPropertyValue("--_p1")
          let currentPercentage1 = parseFloat(
            currentPercentage1String.replace("%", "")
          )

          let currentPercentage2String =
            getComputedStyle(twistElement).getPropertyValue("--_p2")
          let currentPercentage2 = parseFloat(
            currentPercentage2String.replace("%", "")
          )

          let newPercentage1: number = Math.round(
            parseFloat((currentPercentage1 - delta).toFixed(0))
          )
          newPercentage1 = Math.max(75, Math.min(175, newPercentage1))

          let newPercentage2: number = Math.round(
            parseFloat((currentPercentage2 + delta).toFixed(0))
          )
          newPercentage2 = Math.max(75, Math.min(175, newPercentage2))

          twistElement.style.setProperty("--_p1", `${newPercentage1}%`)
          twistElement.style.setProperty("--_p2", `${newPercentage2}%`)

          lastScrollTop = window.scrollY
        }
      }
    }

    // Add the event listener
    window.addEventListener("scroll", handleScroll, { passive: true })

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleMouseEnter = (idx: number) => {
    setHoveredIndex(idx) // Set the hovered image index
    setLastHoveredIndex(idx) // Store the last hovered index
  }

  const handleMouseLeave = () => {
    setHoveredIndex(null) // Reset the hovered index but keep the last hovered
  }

  return (
    <section className="bio-section hp-section" ref={props.routing}>
      <div className="smoke-2-parallel-down"></div>
      <div className="bio-section-splatter-1" />
      <div
        className={`bio-section-content-wrapper section-divider section-divider-bottom twist-border`}
        ref={twistBorderRef}
      >
        <div className="bio-header-and-intro-wrapper">
          <div className="bio-header-img" />
          <div className="bio-mobile-intro-wrapper">
            <div className="bio-shifter-wrapper">
              <div className="bio-shifter-content-wrapper">
                <div className="bio-intro">
                  {array[index % array.length].text}
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            className={
              "bio-section-mobile-shift-carousel-forward-button fade-effect"
            }
            onClick={shiftForward}
            disabled={isDisabled}
          >
            <span className="visually-hidden">Forward</span>
          </button>
          <div className="bio-desktop-intro-wrapper section-divider section-divider-top">
            <div className="bio-desktop-text-and-gallery-wrapper">
              <div className="bio-desktop-image-gallery-wrapper">
                <div className="bio-desktop-image-gallery">
                  {imageArray.map((image, idx) => (
                    <div
                      key={idx}
                      className="bio-desktop-image-item"
                      onMouseEnter={() => handleMouseEnter(idx)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <img
                        className="bio-desktop-image-picture"
                        src={image.src}
                        alt={image.alt}
                      />
                      <div className="bio-image-hover-text">{image.header}</div>
                    </div>
                  ))}
                  {showModal && (
                    <div
                      className="modal "
                      ref={modalRef}
                      onClick={() => setShowModal(false)}
                    >
                      <img
                        src={imageArray[imageIndex].src}
                        alt={imageArray[imageIndex].alt}
                      />
                      <h5 className="bio-desktop-image-header">
                        {imageArray[imageIndex].header}
                      </h5>
                    </div>
                  )}
                </div>
              </div>
              <div className="bio-desktop-text-and-button-wrapper">
                <div className="bio-desktop-text-wrapper">
                  {/* Show bio text based on hover, fallback to last hovered */}
                  {hoveredIndex !== null
                    ? desktopArray[hoveredIndex].text
                    : lastHoveredIndex !== null
                    ? desktopArray[lastHoveredIndex].text
                    : desktopArray[desktopIndex % desktopArray.length].text}
                </div>
                <div className="bio-desktop-text-button-wrapper">
                  <button
                    type="button"
                    className="bio-section-desktop-shift-carousel-forward-button fade-effect"
                    onClick={shiftForwardDesktop}
                    disabled={isDisabledDesktop}
                  >
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
            <p className="bio-desktop-band-members">
              Humavoid are: <br />
              <br />
              Suvimarja Halmetoja - Vocals & Keys
              <br />
              <br />
              Niko Kalliojärvi - Guitar & Vocals
              <br />
              <br />
              Heikki Malmberg - Drums
            </p>
          </div>
        </div>
      </div>
      <div className="bio-section-splatter-2" />
    </section>
  )
}
