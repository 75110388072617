import React, { useRef, RefObject, useEffect, useState } from "react"
import "./StoreSection.css"
import apparel1 from "./../../../../images/store/face-t-shirt.webp"
import apparel2 from "./../../../../images/store/h-logo-t-shirt.webp"
import apparel3 from "./../../../../images/store/lidless-all-over-t-shirt.webp"
import apparel4 from "./../../../../images/store/lidless-2x12-inch-vinyl-gatefolder.webp"
import apparel5 from "./../../../../images/store/lidless-cd-digipak.webp"

export default function StoreSection(props: any) {
  const array = [
    {
      src: apparel1,
      alt: "Face T-shirt",
      url: "https://humavoid.bandcamp.com/merch/face-t-shirt",
      header: "Face T-shirt",
      price: "20,00€",
    },
    {
      src: apparel2,
      alt: "H-logo T-shirt",
      url: "https://humavoid.bandcamp.com/track/monkey-trap",
      header: "H-logo T-shirt",
      price: "20,00€",
    },
    {
      src: apparel3,
      alt: "Lidless All-over T-shirt",
      url: "https://humavoid.bandcamp.com/merch/lidless-all-over-t-shirt",
      header: "Lidless All-over T-shirt",
      price: "39,00€",
    },
    {
      src: apparel4,
      alt: 'Lidless - 2x12" Vinyl Gatefolder',
      url: "https://humavoid.bandcamp.com/album/lidless",
      header: 'Lidless - 2x12" Vinyl Gatefolder',
      price: "25,00€",
    },
    {
      src: apparel5,
      alt: "Lidless - CD Digipak",
      url: "https://humavoid.bandcamp.com/album/lidless",
      header: "Lidless - CD Digipak",
      price: "14,95€",
    },
  ]

  const [index, setIndex] = React.useState(0)
  const [isDisabled, setIsDisabled] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const touchTimeout = useRef<number | NodeJS.Timeout | null>(null)

  const onTouchStart = () => {
    touchTimeout.current = setTimeout(() => {
      setIsHovered(true)
    }, 300)
  }

  const onTouchEnd = () => {
    clearTimeout(touchTimeout.current as number)
    setIsHovered(false) // Deactivate hover effect
  }

  const shiftForward = () => {
    if (isDisabled) {
      return
    }

    const forwardButton = document.querySelector(
      ".apparel-section-shift-carousel-forward-button"
    )
    forwardButton?.classList.add("faded")

    setTimeout(() => {
      forwardButton?.classList.remove("faded")
    }, 500) // 1 second delay before the button comes back. Adjust as needed.

    if (index === array.length - 1) {
      setIndex(0)
    } else if (index < array.length - 1) {
      setIndex((index + 1) % array.length)
    } else {
    }
    setIsDisabled(true)

    setTimeout(() => {
      setIsDisabled(false)
    }, 500)
  }

  const shiftBackwards = () => {
    if (isDisabled) {
      return
    }

    const backButton = document.querySelector(
      ".apparel-section-shift-carousel-backwards-button"
    )
    backButton?.classList.add("faded")

    setTimeout(() => {
      backButton?.classList.remove("faded")
    }, 500) // 1 second delay before the button comes back. Adjust as needed.

    if (index === 0) {
      setIndex(array.length - 1)
    } else if (index > 0) {
      setIndex((index - 1) % array.length)
    }
    setIsDisabled(true)

    setTimeout(() => {
      setIsDisabled(false)
    }, 500)
  }

  const twistBorderRef: RefObject<HTMLDivElement> = useRef(null)

  useEffect(() => {
    let lastScrollTop = 0

    const twistElement = twistBorderRef.current
    // Set initial values
    if (twistElement) {
      twistElement.style.setProperty("--_p1", "75%")
      twistElement.style.setProperty("--_p2", "175%")
    }

    const handleScroll = () => {
      const twistElement = twistBorderRef.current
      if (twistElement) {
        const rect = twistElement.getBoundingClientRect()
        if (rect.top < window.innerHeight && rect.bottom > 0) {
          // Check if BioSection is in the viewport
          let delta = window.scrollY - lastScrollTop

          let currentPercentage1String =
            getComputedStyle(twistElement).getPropertyValue("--_p1")
          let currentPercentage1 = parseFloat(
            currentPercentage1String.replace("%", "")
          )

          let currentPercentage2String =
            getComputedStyle(twistElement).getPropertyValue("--_p2")
          let currentPercentage2 = parseFloat(
            currentPercentage2String.replace("%", "")
          )

          let newPercentage1: number = Math.round(
            parseFloat((currentPercentage1 - delta).toFixed(0))
          )
          newPercentage1 = Math.max(75, Math.min(175, newPercentage1))

          let newPercentage2: number = Math.round(
            parseFloat((currentPercentage2 + delta).toFixed(0))
          )
          newPercentage2 = Math.max(75, Math.min(175, newPercentage2))

          twistElement.style.setProperty("--_p1", `${newPercentage1}%`)
          twistElement.style.setProperty("--_p2", `${newPercentage2}%`)

          lastScrollTop = window.scrollY
        }
      }
    }

    // Add the event listener
    window.addEventListener("scroll", handleScroll, { passive: true })

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <section className="store-section hp-section" ref={props.routing}>
      <div className="smoke-2-parallel-up"></div>
      <div className="store-section-splatter-1"></div>
      <div className="store-section-content-wrapper">
        <div className="store-header-img" />
        <div className="store-section-intro-and-catalogue-wrapper section-divider section-divider-both">
          <p className="store-intro-text">
            Welcome to the official Humavoid store – where chaos, precision, and
            raw energy collide! Gear up with exclusive merch that captures the
            band’s fusion of structure and unpredictability. From killer
            t-shirts that scream attitude to mind-bending vinyls and CDs that
            reveal hidden patterns in the madness, every item embodies the
            oddities of existence. Step into the mayhem, support the art, and
            wear it loud!
          </p>
          <div className="shifter-wrapper">
            <h3 className="apparel-item-header">
              {array[index % array.length].header}
            </h3>
            <div className="apparel-shifter-wrapper">
              <button
                type="button"
                className={
                  "apparel-section-shift-carousel-backwards-button fade-effect"
                }
                onClick={shiftBackwards}
                disabled={isDisabled}
              >
                <span className="visually-hidden">Backwards</span>
              </button>
              <div className="apparel-item-wrapper">
                <div
                  className={`apparel-shifter-content-wrapper twist-border`}
                  ref={twistBorderRef}
                  onTouchStart={onTouchStart}
                  onTouchEnd={onTouchEnd}
                  onTouchCancel={onTouchEnd}
                >
                  <a
                    href={array[index % array.length].url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="apparel-item">
                      <img
                        className="apparel-picture"
                        src={array[index % array.length].src}
                        alt={array[index % array.length].alt}
                      />
                    </div>
                  </a>
                  <div className="apparel-desktop-item apparel-desktop-item-1">
                    <a
                      href={array[(index + 1) % array.length].url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="apparel-desktop-item-wrapper">
                        <div className="apparel-desktop-picture-wrapper">
                          <img
                            className="apparel-desktop-picture"
                            src={array[(index + 1) % array.length].src}
                            alt={array[(index + 1) % array.length].alt}
                          />
                        </div>
                        <div className="apparel-desktop-item-info">
                          <h5 className="apparel-desktop-item-header">
                            {array[(index + 1) % array.length].header}
                          </h5>
                          <div className="apparel-desktop-item-button-and-price">
                            <button
                              type="button"
                              className="apparel-desktop-item-button"
                            >
                              ADD TO CART
                            </button>
                            <h5 className="apparel-desktop-item-price">
                              {array[(index + 1) % array.length].price}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="apparel-desktop-item apparel-desktop-item-2">
                    <a
                      href={array[(index + 2) % array.length].url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="apparel-desktop-item-wrapper">
                        <div className="apparel-desktop-picture-wrapper">
                          <img
                            className="apparel-desktop-picture"
                            src={array[(index + 2) % array.length].src}
                            alt={array[(index + 2) % array.length].alt}
                          />
                        </div>
                        <div className="apparel-desktop-item-info">
                          <h5 className="apparel-desktop-item-header">
                            {array[(index + 2) % array.length].header}
                          </h5>
                          <div className="apparel-desktop-item-button-and-price">
                            <button
                              type="button"
                              className="apparel-desktop-item-button"
                            >
                              ADD TO CART
                            </button>
                            <h5 className="apparel-desktop-item-price">
                              {array[(index + 2) % array.length].price}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="apparel-desktop-item apparel-desktop-item-3">
                    <a
                      href={array[(index + 3) % array.length].url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="apparel-desktop-item-wrapper">
                        <div className="apparel-desktop-picture-wrapper">
                          <img
                            className="apparel-desktop-picture"
                            src={array[(index + 3) % array.length].src}
                            alt={array[(index + 3) % array.length].alt}
                          />
                        </div>
                        <div className="apparel-desktop-item-info">
                          <h5 className="apparel-desktop-item-header">
                            {array[(index + 3) % array.length].header}
                          </h5>
                          <div className="apparel-desktop-item-button-and-price">
                            <button
                              type="button"
                              className="apparel-desktop-item-button"
                            >
                              ADD TO CART
                            </button>
                            <h5 className="apparel-desktop-item-price">
                              {array[(index + 3) % array.length].price}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="apparel-desktop-item apparel-desktop-item-4">
                    <a
                      href={array[(index + 4) % array.length].url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="apparel-desktop-item-wrapper">
                        <div className="apparel-desktop-picture-wrapper">
                          <img
                            className="apparel-desktop-picture"
                            src={array[(index + 4) % array.length].src}
                            alt={array[(index + 4) % array.length].alt}
                          />
                        </div>
                        <div className="apparel-desktop-item-info">
                          <h5 className="apparel-desktop-item-header">
                            {array[(index + 4) % array.length].header}
                          </h5>
                          <div className="apparel-desktop-item-button-and-price">
                            <button
                              type="button"
                              className="apparel-desktop-item-button"
                            >
                              ADD TO CART
                            </button>
                            <h5 className="apparel-desktop-item-price">
                              {array[(index + 4) % array.length].price}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <button
                type="button"
                className={
                  "apparel-section-shift-carousel-forward-button fade-effect"
                }
                onClick={shiftForward}
                disabled={isDisabled}
              >
                <span className="visually-hidden">Forward</span>
              </button>
            </div>
            <a
              href={array[index % array.length].url}
              target="_blank"
              rel="noreferrer"
            >
              <h3 className="apparel-item-price">
                {array[index % array.length].price}
              </h3>
            </a>
          </div>
        </div>
      </div>
      <div className="store-section-splatter-2"></div>
    </section>
  )
}
