import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import { App } from "./app/App"
import reportWebVitals from "./reportWebVitals"
import { HelmetProvider } from "react-helmet-async"
import Loader from "./app/components/loader/Loader" // Adjust the import path as necessary

const Root = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 2000)
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    if (!loading) {
      document.body.classList.remove("lock-scroll")
      document.body.classList.add("loaded")
    } else {
      document.body.classList.add("lock-scroll")
    }
  }, [loading])

  if (loading) {
    return <Loader />
  } else {
    return (
      <div className="app-content">
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </div>
    )
  }
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(<Root />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
