import React from "react"
import "./HomeSection.css"

export default function HomeSection(props: any) {
  return (
    <section className="home-section hp-section" ref={props.routing}>
      <div className="home-section-splatter-1"></div>
      <div className="home-section-content-wrapper"></div>
      <div className="home-section-splatter-2"></div>
    </section>
  )
}
