import React from "react"
import "./Loader.css"
import { LineWave } from "react-loader-spinner"

const Loader = () => {
  return (
    <div className="loader">
      <LineWave
        visible={true}
        height="100"
        width="100"
        color="#ff9900"
        ariaLabel="line-wave-loading"
        wrapperStyle={{}}
        wrapperClass=""
        firstLineColor=""
        middleLineColor=""
        lastLineColor=""
      />
    </div>
  )
}

export default Loader
