function smoothScroll(targetY: number, duration: number) {
  const startY = window.pageYOffset
  const distance = targetY - startY
  let startTime: number | null = null

  function easeInOutQuad(t: number) {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t
  }

  function scrollStep(timestamp: number) {
    if (!startTime) {
      startTime = timestamp
    }

    const elapsedTime = timestamp - startTime
    const progress = Math.min(elapsedTime / duration, 1)
    const easedProgress = easeInOutQuad(progress)
    const currentPosition = startY + distance * easedProgress

    window.scrollTo(0, currentPosition)

    if (progress < 1) {
      requestAnimationFrame(scrollStep)
    }
  }

  requestAnimationFrame(scrollStep)
}

export default smoothScroll
