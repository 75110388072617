import React from "react"
import "./TourSection.css"

type Gig = {
  date: string
  location: string
  ticketUrl: string
}

export default function TourSection(props: any) {
  const array: Gig[] = [
    /*{
      date: '01.06.2023',
      location: 'GIGPLACE, HELSINKI',
      ticketUrl: 'https://example.com/tickets',
    },*/
  ]

  return (
    <section className="tour-section hp-section" ref={props.routing}>
      <div className="smoke-1-parallel-down"></div>
      <div className="tour-section-content-wrapper">
        <div className="tour-section-splatter-1"></div>
        <div className="tour-header-img" />
        <p className="tour-text">
          Although there are no tour dates currently, the band is fully charged
          and ready for what’s next. This time off has given us the space to
          push our sound further, experiment, and create something that feels
          more powerful and personal than ever. We’re in the studio, taking our
          time to perfect the songs that will become the soundtrack to our next
          tour.
        </p>
        <h4 className="tour-title">UPCOMING SHOWS</h4>
        <div className="upcoming-shows-wrapper">
          {array.length > 0 ? (
            array.map((item, key) => (
              <div className="tour-item" key={key}>
                <h5 className="gig-date">{item.date}</h5>
                <h5 className="gig-location">{item.location}</h5>
                {item.ticketUrl ? (
                  <a href={item.ticketUrl}>
                    <h4 className="buy-tickets-button">BUY TICKETS</h4>
                  </a>
                ) : (
                  <h4 className="buy-tickets-button">NOT AVAILABLE</h4>
                )}
              </div>
            ))
          ) : (
            <h3 className="tour-tba">TBA</h3>
          )}
        </div>
      </div>
    </section>
  )
}
