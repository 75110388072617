import React from "react"
import "./ContactSection.css"
import instagramIcon from "./../../../../images/page_elements/IG_Button.svg"
import youtubeIcon from "./../../../../images/page_elements/Youtube_Button.svg"
import facebookIcon from "./../../../../images/page_elements/FB_Button.svg"

type Contact = {
  header: string
  obscuredMailto: string
  email: string
  phone: string
}

type ContactSectionProps = {
  contact: React.RefObject<HTMLElement>
  news: React.RefObject<HTMLElement>
  press: React.RefObject<HTMLElement>
}

export default function ContactSection(props: ContactSectionProps) {
  const array: Contact[] = [
    {
      header: "Humavoid",
      obscuredMailto: `mailto&#58;humavoid&#64;gmail&#46;com`,
      email: "humavoid [at] gmail [dot] com",
      phone: "+358 44 542 4737",
    },
  ]
  return (
    <section className="contact-section hp-section" ref={props.contact}>
      <div className="smoke-1-parallel-up"></div>
      <div className="contact-section-content-wrapper">
        <div className="contact-header-img" />
        <div className="contact-info-wrapper">
          {array.length > 0 ? (
            array.map((item, key) => (
              <div className="contact-item" key={key}>
                <h3 className="contact-item-header">{item.header}</h3>
                <div className="contact-item-info">
                  <a href={item.email}>
                    <p className="contact-email">{item.email}</p>
                  </a>
                  <p className="contact-phone">{item.phone}</p>
                </div>
              </div>
            ))
          ) : (
            <div className="contact-none">-</div>
          )}
        </div>
        <section className="contact-social-medias" ref={props.news}>
          <h2 className="get-connected-header">GET CONNECTED</h2>
          <div className="get-connected-icons">
            <a
              href={"https://www.instagram.com/humavoidband/"}
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="instagram-img"
                className="contact-social-media-icon"
                src={instagramIcon}
              />
            </a>

            <a
              href={"https://www.youtube.com/humavoid/"}
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="youtube-img"
                className="contact-social-media-icon"
                src={youtubeIcon}
              />
            </a>

            <a
              href={"https://www.facebook.com/Humavoid/"}
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="facebook-img"
                className="contact-social-media-icon"
                src={facebookIcon}
              />
            </a>
          </div>
        </section>
        <section className="press-section" ref={props.press}>
          <h2 className="press-header">PRESS</h2>
          <div className="press-content">
            <div className="header-and-donwload-wrapper">
              <h2 className="press-content-header">FOR MEDIA</h2>
              <a href={""}>
                <p className="press-download-all">download</p>
              </a>
            </div>
            <div className="press-download-categories">
              <a href={""}>
                <h4 className="download-content-button">LOGOS</h4>
              </a>
              <a href={""}>
                <h4 className="download-content-button">PHOTOS</h4>
              </a>
              <a href={""}>
                <h4 className="download-content-button">VIDEOS</h4>
              </a>
            </div>
          </div>
        </section>
      </div>
    </section>
  )
}
