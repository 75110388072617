import React from "react"
import { useState } from "react"
import "./MusicSection.css"
import lidless from "./../../../../images/discography/lidless.webp"
import monkeytrap from "./../../../../images/discography/monkeytrap.webp"
import glass from "./../../../../images/discography/glass.webp"
import comahorizon from "./../../../../images/discography/comahorizon.webp"
import spotifyIcon from "./../../../../images/page_elements/Spotify_Button_Small.svg"
import itunesIcon from "./../../../../images/page_elements/Itunes_Button_Small.svg"
import deezerIcon from "./../../../../images/page_elements/Deezer_Button_Small.svg"
import amazonIcon from "./../../../../images/page_elements/Amazon_Button_Small.svg"

export default function MusicSection(props: any) {
  const videos = [
    {
      url: "https://www.youtube.com/embed/k7bBtZBRvS8",
      alt: "Humavoid Aluminum Rain (feat. Iiro Rantala)",
      header: "Humavoid - Aluminum Rain (feat. Iiro Rantala)",
    },
    {
      url: "https://www.youtube.com/embed/QS87vdQX-IM",
      alt: "Humavoid - Lidless",
      header: "Humavoid - Lidless",
    },
    {
      url: "https://www.youtube.com/embed/V9duciRi2EA",
      alt: "Humavoid - Coma Horizon",
      header: "Humavoid - Coma Horizon",
    },
  ]

  const releases = [
    {
      src: lidless,
      alt: "lidless",
      url: "https://humavoid.bandcamp.com/album/lidless",
      header: "LIDLESS",
    },
    {
      src: monkeytrap,
      alt: "monkeytrap",
      url: "https://humavoid.bandcamp.com/track/monkey-trap",
      header: "MONKEY TRAP",
    },
    {
      src: comahorizon,
      alt: "comahorizon",
      url: "https://humavoid.bandcamp.com/track/coma-horizon",
      header: "COMA HORIZON",
    },
    {
      src: glass,
      alt: "glass",
      url: "https://humavoid.bandcamp.com/album/glass",
      header: "GLASS",
    },
  ]

  const [videoIndex, setVideoIndex] = React.useState(0)
  const [isDisabled, setIsDisabled] = useState(false)

  const shiftVideoForward = () => {
    if (isDisabled) {
      return
    }

    const forwardButton = document.querySelector(
      ".music-section-shift-carousel-forward-button"
    )
    forwardButton?.classList.add("faded")

    setTimeout(() => {
      forwardButton?.classList.remove("faded")
    }, 500) // 1 second delay before the button comes back. Adjust as needed.

    if (videoIndex === videos.length - 1) {
      setVideoIndex(0)
    } else if (videoIndex < videos.length - 1) {
      setVideoIndex(videoIndex + 1)
    } else {
    }
    setIsDisabled(true)

    setTimeout(() => {
      setIsDisabled(false)
    }, 500)
  }

  const shiftVideoBackwards = () => {
    if (isDisabled) {
      return
    }

    const backButton = document.querySelector(
      ".music-section-shift-carousel-backwards-button"
    )
    backButton?.classList.add("faded")

    setTimeout(() => {
      backButton?.classList.remove("faded")
    }, 500) // 1 second delay before the button comes back. Adjust as needed.

    if (videoIndex === 0) {
      setVideoIndex(videos.length - 1)
    } else if (videoIndex > 0) {
      setVideoIndex(videoIndex - 1)
    }

    setIsDisabled(true)

    setTimeout(() => {
      setIsDisabled(false)
    }, 500)
  }

  return (
    <section className="music-section hp-section" ref={props.routing}>
      <div className="smoke-1-parallel-up"></div>
      <div className="music-section-splatter-1"></div>
      <div className="music-section-content-wrapper section-divider-bottom">
        <div className="music-header-img"></div>
        <div className="music-section-text-and-video-wrapper">
          <p className="music-section-text">
            Take a deep breath before these progressive-aggressive Finns take
            you on a rollercoaster of sophisticated madness. Backing their dual
            scream attack with a multidimensional hybrid of down tuned high tech
            guitar riffs and jazz fusion piano licks, Humavoid delivers a
            blistering and unpredictable blend of Progressive Metal that
            effortlessly shifts from chaotic beating to contagious refrains and
            back.
            <br />
            <br />
            Since its inception in 2014, Humavoid has put out a few independent
            releases with praising reviews and steadily grown their fanbase
            through vigorous live shows. In 2020 the band signed a recording
            contract with German label Noble Demon and released their latest
            album Lidless.
            <br />
            <br />
            Lidless presents Humavoid as its most aggressive to date, but also
            further reveals the band’s experimental side. <br />
            <br />
          </p>
          <div className="music-section-text-and-music-wrapper">
            <div className="music-video-player-and-header-wrapper">
              <div className="music-section-header-wrapper"></div>
              <div className="music-video-player-wrapper">
                <div
                  className={
                    "music-section-shift-carousel-backwards-button fade-effect"
                  }
                  onClick={shiftVideoBackwards}
                ></div>
                <div className="music-video-wrapper">
                  <iframe
                    src={videos[videoIndex].url}
                    allowFullScreen
                    title="Embedded youtube"
                  />
                </div>
                <div
                  className={
                    "music-section-shift-carousel-forward-button fade-effect"
                  }
                  onClick={shiftVideoForward}
                ></div>
              </div>
              <h5 className="music-video-header">
                {videos[videoIndex].header}
              </h5>
            </div>
            <div className="releases-and-icons-wrapper">
              <div className="releases-wrapper">
                <div className="releases-and-icons-inner-wrapper">
                  <div className="albums-shifter-wrapper">
                    {releases.map((release, currentRelease) => (
                      <div className="albums-wrapper" key={currentRelease}>
                        <a href={release.url} target="_blank" rel="noreferrer">
                          <img
                            className="albums-item"
                            src={release.src}
                            alt={release.alt}
                          />
                        </a>
                        <h5 className="albums-item-header">{release.header}</h5>
                      </div>
                    ))}
                  </div>
                  <div className="music-service-icons-wrapper">
                    <div className="icon-wrapper">
                      <a
                        href="https://open.spotify.com/artist/1EnnVL11TLWqsFMdtI1kxj"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          alt="spotify-icon"
                          className="music-icon"
                          src={spotifyIcon}
                        />
                      </a>
                      <a
                        href="https://music.apple.com/us/artist/humavoid/893472260"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          alt="itunes-icon"
                          className="music-icon"
                          src={itunesIcon}
                        />
                      </a>
                      <a
                        href="https://www.deezer.com/fi/artist/6753937"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          alt="deezer-img"
                          className="music-icon"
                          src={deezerIcon}
                        />
                      </a>
                      <a
                        href="https://music.amazon.com/artists/B00LFR7MCG/humavoid"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          alt="amazon-img"
                          className="music-icon"
                          src={amazonIcon}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="music-section-splatter-2"></div>
    </section>
  )
}
