import "./App.css"
import * as React from "react"
import { Helmet } from "react-helmet-async"
import { useRef, useEffect, useState } from "react"
import Loader from "./components/loader/Loader"
import BioSection from "./pages/public/BioSection/BioSection"
import ContactSection from "./pages/public/ContactSection/ContactSection"
import HomeSection from "./pages/public/HomeSection/HomeSection"
import MusicSection from "./pages/public/MusicSection/MusicSection"
import StoreSection from "./pages/public/StoreSection/StoreSection"
import TourSection from "./pages/public/TourSection/TourSection"
import smoothScroll from "scripts/smoothScroll"

export function App() {
  const home = useRef(null)
  const music = useRef(null)
  const bio = useRef(null)
  const tour = useRef(null)
  const store = useRef(null)
  const contact = useRef(null)
  const press = useRef(null)
  const news = useRef(null)
  const [background, setBackground] = useState(false)
  const [activeSection, setActiveSection] = useState("home")
  const [showMobileNav, setShowMobileNav] = useState(false)
  const [isAnimating, setIsAnimating] = useState(false)

  const toggleMobileNav = () => {
    if (!isAnimating) {
      setIsAnimating(true)
      setShowMobileNav(!showMobileNav)
      setTimeout(() => {
        setIsAnimating(false)
      }, 300)
    }
  }

  const scrollToSection = (elementRef, section, isMobileNav = false) => {
    setActiveSection(section)
    const sectionOffsetTop = elementRef.current.offsetTop
    let offsetY = 0
    switch (section) {
      case "home":
        offsetY = isMobileNav ? -100 : 0
        break
      case "music":
        offsetY = isMobileNav ? -100 : -50
        break
      case "bio":
        offsetY = isMobileNav ? 100 : 150
        break
      case "tour":
        offsetY = isMobileNav ? 100 : 50
        break
      case "store":
        offsetY = isMobileNav ? 0 : 200
        break
      case "contact":
        offsetY = isMobileNav ? 50 : 100
        break
      case "press":
        offsetY = isMobileNav ? 0 : 0
        break
      case "news":
        offsetY = isMobileNav ? 0 : 0
        break
      default:
        break
    }
    smoothScroll(sectionOffsetTop + offsetY, 400)
  }

  useEffect(() => {
    window.addEventListener("load", () => {
      document.body.classList.add("loaded")
    })
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      if (home && isSectionActive(home)) {
        setActiveSection("home")
      } else if (music && isSectionActive(music)) {
        setActiveSection("music")
      } else if (bio && isSectionActive(bio)) {
        setActiveSection("bio")
      } else if (tour && isSectionActive(tour)) {
        setActiveSection("tour")
      } else if (store && isSectionActive(store)) {
        setActiveSection("store")
      } else if (contact && isSectionActive(contact)) {
        setActiveSection("contact")
      } else if (press && isSectionActive(press)) {
        setActiveSection("press")
      } else if (news && isSectionActive(news)) {
        setActiveSection("news")
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 215) {
        setBackground(true)
      } else {
        setBackground(false)
      }
    }

    window.addEventListener("scroll", onScroll)

    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [])

  const isSectionActive = (elementRef) => {
    const elementTop = elementRef.current.offsetTop
    const elementBottom =
      elementRef.current.offsetTop + elementRef.current.offsetHeight
    const windowTop = window.scrollY
    const windowBottom = window.scrollY + window.innerHeight
    return windowTop >= elementTop && windowBottom <= elementBottom
  }

  return (
    <>
      <Helmet titleTemplate="%s" defaultTitle="Humavoid - Official">
        <meta name="description" content="Humavoid - Official" />
      </Helmet>
      <Loader />
      <main className="landing-page-wrapper">
        <div className="humavoid-logo" />
        <header
          className={`${background ? "header-scrolled" : "header-unscrolled"}`}
        >
          <div className="burger-icon" onClick={toggleMobileNav}>
            <div
              className={`fa ${showMobileNav ? "fa-times" : "fa-bars"}`}
            ></div>
          </div>
          <div className="humavoid-mobile-logo" />
          <nav className="navigation-bar">
            <ul className="nav-links-wrapper">
              <li
                onClick={() => scrollToSection(home, "home")}
                className={activeSection === "home" ? "active" : ""}
              >
                <h5 className="nav-links-text">HOME</h5>
              </li>
              <li
                onClick={() => scrollToSection(music, "music")}
                className={activeSection === "music" ? "active" : ""}
              >
                <h5 className="nav-links-text">MUSIC</h5>
              </li>
              <li
                onClick={() => scrollToSection(bio, "bio")}
                className={activeSection === "bio" ? "active" : ""}
              >
                <h5 className="nav-links-text">BIO</h5>
              </li>
              <li
                onClick={() => scrollToSection(tour, "tour")}
                className={activeSection === "tour" ? "active" : ""}
              >
                <h5 className="nav-links-text">TOUR</h5>
              </li>
              <li
                onClick={() => scrollToSection(store, "store")}
                className={activeSection === "store" ? "active" : ""}
              >
                <h5 className="nav-links-text">STORE</h5>
              </li>
              <li
                onClick={() => scrollToSection(contact, "contact")}
                className={activeSection === "contact" ? "active" : ""}
              >
                <h5 className="nav-links-text">CONTACT</h5>
              </li>
              {/*<li
                onClick={() => scrollToSection(press, "press")}
                className={activeSection === "press" ? "active" : ""}
              >
                <h5 className="nav-links-text">PRESS</h5>
              </li>
              <li
                onClick={() => scrollToSection(news, "news")}
                className={activeSection === "news" ? "active" : ""}
              >
                <h5 className="nav-links-text">NEWS</h5>
              </li>*/}
            </ul>
          </nav>
        </header>
        {showMobileNav && (
          <div className={`mobile-nav ${showMobileNav ? "active" : ""}`}>
            <ul>
              <li onClick={() => scrollToSection(home, "home", true)}>HOME</li>
              <li onClick={() => scrollToSection(music, "music", true)}>
                MUSIC
              </li>
              <li onClick={() => scrollToSection(bio, "bio", true)}>BIO</li>
              <li onClick={() => scrollToSection(tour, "tour", true)}>TOUR</li>
              <li onClick={() => scrollToSection(store, "store", true)}>
                STORE
              </li>
              <li onClick={() => scrollToSection(contact, "contact", true)}>
                CONTACT
              </li>
              {/*<li onClick={() => scrollToSection(press, "press", true)}>PRESS</li>
              <li onClick={() => scrollToSection(news, "news", true)}>NEWS</li>*/}
            </ul>
          </div>
        )}
        <div className="section-wrapper">
          <HomeSection routing={home} />
          <MusicSection routing={music} />
          <BioSection routing={bio} />
          <TourSection routing={tour} />
          <StoreSection routing={store} />
          <ContactSection contact={contact} news={news} press={press} />
        </div>
        <footer className="footer">
          <div className="footer-splatter-yellow"></div>
          <div className="footer-splatter-black"></div>
        </footer>
      </main>
    </>
  )
}
